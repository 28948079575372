<template lang="pug">
  div
    v-app-bar(color="white" dense flat)
      div.my-4.h6.primary500--text  {{ $t('your_cart') }}
    v-container(v-if="isReloading")
      v-row.fill-height(no-gutters align="center" justify="center")
        v-col.my-6
          div.text-center
            v-progress-circular.ma-auto(:width="2" :size="50" color="primary" indeterminate)
            div.s1.mt-4 {{ $t('refreshing') }}...
    router-view(v-else)
</template>

<script>
export default {
  name: 'Home',
  data: () => ({
    isReloading: false
  })
}
</script>
